<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-2/3 mb-6 md:pr-2">
        <TitleComponent title="The 3 Most Common Facility Siting Errors" />
        <Paragraph
          ><p>
            At SMS, we review, prepare, and consult on site plans of all shapes
            and sizes. Whether it’s for government work or purely commercial, HD
            1.1 or HD 1.4, we’ve seen almost everything. Continually we see the
            same errors pop up, specifically when the site plan needs to follow
            the “<em
              >Contractor’s Safety Manual for Ammunition and Explosives</em
            >,” or DoD 4145.26-M. These errors, oversights, or omissions can
            cause delays in site plan approval and stress for process owners and
            reviewers.
          </p>
          <ol>
            <li>
              <strong>Not taking into account fragmentation hazards</strong>
            </li>
          </ol>
          <p>
            When dealing with HD 1.1 articles and substances, fragmentation is a
            major concern. For quantities under 30,000 pounds the distance for
            IBD and PTRD is controlled by debris and fragments (DoD 4145 para
            C5.5.1). Above this quantity, fragments are still an issue, but the
            major hazard in the far field is overpressure. There are two major
            types of fragmentation to consider: primary fragments and secondary
            fragments.
          </p>
          <p><em>Primary fragments</em></p>
          <p>
            These fragments are generally in intimate contact with the article
            and move at speeds of thousands of feet per second. If these
            fragments are not properly stopped by a qualified barricade or other
            surface, then they must be taken into account for quantity distance
            (QD) purposes. QD calculations for primary fragments are found in
            the “Open” column of Table AP2.T2 of DoD 4145. Primary fragmentation
            is the primary hazard in HD 1.1 quantities up to 450 pounds.
          </p>
          <p><em>Secondary fragments</em></p>
          <p>
            &nbsp;Tables, chairs, desks, building debris, etc. are considered
            secondary fragments, and are sometimes referred to as hazardous
            debris. These fragments move initially at hundreds of feet per
            second and are not stopped by barricading because the debris can be
            lobbed great distances. When primary fragments are stopped, but
            secondary fragments are still a concern, then the “Structure” column
            of Table AP2.T2 is used for QD calculations. Above 450 pounds of HD
            1.1, the differentiation between primary and secondary fragments is
            no longer made.
          </p>
          <p>
            Hazardous fragment distance, or HFD, is highly dependent on the
            quantity of material, the type of material, the PES-ES pair, and a
            few other factors. The error we see most frequently is only taking
            into account overpressure hazards for HD 1.1 and not fragments.
            Additional guidance for this concept is found in paragraph C5.8 of
            DoD 4145.
          </p>
          <ol start="2">
            <li><strong>Incorrect application of ILD and IBD</strong></li>
          </ol>
          <p>
            Businesses and processes change over the years. Increasingly, we are
            noticing an uptick in companies that have merged, split, been
            bought, or sold off property for a variety of reasons. This can
            create significant site planning issues in some cases. IBD, or
            inhabited building distance, is the distance required from an
            explosive location and an exposed site unrelated to the explosives
            mission. ILD, or intraline distance, is the distance required
            between two sites that are related to the explosives mission. When a
            building that was previously “related to the explosives mission” is
            sold off, it is now considered outside of the plant boundary, to
            which IBD is required. Even if the purchasing company is also an
            explosives manufacturer, the two companies remain completely
            separate in the eyes of the reviewer. Where ILD was once the
            requirement, IBD now governs. This can severely limit the capacity
            of both buildings and in some cases, cause the operations to be
            relocated. Prior to any purchasing or selling deals, SMS highly
            recommends analyzing the implications to the facility site plan.
          </p>
          <ol start="3">
            <li><strong>Disregarding HD mixing rules</strong></li>
          </ol>
          <p>
            Energetic processes often require multiple types of substances and
            mixtures to make an article function as intended. Time delays,
            igniters, flight termination devices, warheads, inflators, and other
            components often make up a final product. Each of these components
            contains a different material designed to function in a different
            way, and when they are all stored or processed together, certain
            rules apply.
          </p>
          <p>
            Rules for mixed HD storage are found in paragraph C5.3.2 of DoD
            4145. Perhaps the most common mistake is when HD 1.1 is stored with
            other hazard divisions. HD 1.3 is the hazard division containing
            propellants or other articles where the primary hazard is a mass
            fire. But some HD 1.3, when boosted by a detonating donor charge,
            can act as an HD 1.1, or mass detonating. This is why when HD 1.1
            and HD 1.3 are stored or processed together, the entire mixture is
            summed and treated as HD 1.1. For example, if you have 500 pounds of
            propellant together with 1 pound of HD 1.1, that entire mixture
            would be treated as 501 pounds of HD 1.1 for an IBD distance
            requirement of 1,250 feet. Compare this to as little as 200 feet
            separation distance for the single pound of HD 1.1 or 75 feet for
            just the HD 1.3 and you can see how disregarding this mixing rule
            can cripple a process. When determining net explosive weight for QD
            (NEWQD) it is imperative to factor in all forms of HD mixing located
            in and around the PES.
          </p>
          <p>
            Facility siting based on DoD 4145 can be a complex process for some
            sites. Finding and correcting errors early on in the preparation,
            preferably before a site is even constructed, will allow for greater
            operational flexibility and most importantly, provide a safe working
            environment for employees and keep the public safe.
          </p>
          <p>
            If you have any questions about your site plan or want to schedule a
            review, please contact SMS.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Facility Siting Errors",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The three most common facility siting hazards are not considering fragmentation hazards, incorrect application of ILD and IBD, disregarding HD mixing rules."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
